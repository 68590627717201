/**
 * A promise you can stop
 *
 * @packageDocumentation
 * @module cancelable-promise
 */
import { CancelablePromise } from './CancelablePromise';
import { CancelError } from './CancelError';

export type { CancelError };

export default CancelablePromise;
